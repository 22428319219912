











import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ShowcaseLarge extends Vue {
  @Prop({ default: "Lorem ipsum" }) content?: string;
  @Prop({ default: { formats: { large: { url: "/some-url" } } } })
  image?: string;
  @Prop({ default: "white" }) color?: string;
}
